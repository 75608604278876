<template>
  <div id="friendswishlist-view" class="mt-2">
    <p v-if="imageshow">
      <span class="d-flex justify-content-center"
        ><img
          class="img-fluid text-center"
          src="@/assets/site/images/Group1.svg"
          alt=""
      /></span>
    </p>
    <div class="container-fluid" v-if="productShow">
      <!-- <center>
        <div class="findgft">
          <h1 class="display-1 mb-0" style="font-weight: 400;"><span> <img class="giftbox-img mr-2" src="@/assets/site/images/gift-box-left.svg" alt=""></span>My WISHLIST <span> <img class="giftbox-img ml-4" src="@/assets/site/images/gift-box-right.svg" alt=""></span></h1>
        </div>
      </center> -->

      <div class="product-slider product-slider-1">
        <div
          id="sm_filterproducts_1610096371335085279"
          class="products-grid mt-4"
        >
          <div class="row">
            <div class="col-md-3" v-for="(giftproduct, key) in giftproducts">
              <div class="owl-item active wizard-box">
                <div class="item product product-item">
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <div class="label-sale pull-right">
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          @click="deleteGift(giftproduct, key)"
                          class="fa fa-trash"
                          v-b-popover.hover.top="'Delete'"
                        ></b-button>
                      </div>
                      <a
                        @click="ViewSingleProduct(giftproduct)"
                        class="product photo product-item-photo"
                        tabindex="-1"
                      >
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%"
                          >
                            <img

                              class="wizard-image-photo lazyload"
                              :src="giftproduct.imageurl"
                              data-src=""
                              loading="lazy"
                              max-width="300"
                              max-height="300"
                              alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                          /></span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <!-- <a href="#" data-post="" @click="savetoFavourite(product)"
                            class="action towishlist btn-action link-wishlist" data-action="add-to-favourite"
                            title="Add to Favourite">
                            <span>Add to Favourite</span>
                          </a>
                         <a href="#" data-post="" @click="savetoWishlist(product)"
                            class="action tocart primary btn-action btn-cart" data-action="add-to-wishlist"
                            title="Add to Wish List">
                            <span>Add to Wish List</span>
                          </a>
                          <a href="#" class="action tocompare btn-action link-compare" @click="compareProduct(product)"
                            data-post="" title="Add to Compare">
                            <span>Add to Compare</span>
                          </a> -->

                        <a
                          href="#"
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click="ViewSingleProduct(giftproduct)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ giftproduct.productname }}
                        </a>
                      </h2>

                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper"
                            ><span class="price"
                              >{{ giftproduct.price["@currency"] }}
                              {{ giftproduct.price["#text"] }}</span
                            ></span
                          >
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%"><span>87%</span></span>
                          </div>
                        </div>
                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <gift-product
        v-if="!productShow"
        v-on:back="back"
        v-bind:sproduct="sproduct"
      ></gift-product>
    </div>
  </div>
</template>
<script>
import GiftProduct from "@/views/site/user/GiftProduct";

export default {
  name: "GiftView",
  props: ["giftproducts"],
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      imageshow: false,
      ViewProduct: false,
      sproduct: null,
      productShow: true,
    };
  },
  components: {
    GiftProduct,
  },
  created() {
    // console.log(this.giftproducts);
  },
   watch: {
    giftproducts: function (newVal, oldVal) {
      this.changeOccationProduct();
    },
  },
  methods: {

      changeOccationProduct() {
      if (this.productShow == false) {
        this.productShow = true;
      }
    },  

    ViewSingleProduct(product) {
      this.productShow = false;
      this.sproduct = product;
    },
    back() {
      this.productShow = true;
      this.sproduct = null;
    },
    deleteGift(giftproduct, index) {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      this.$swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to remove  " + giftproduct.productname + "!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {


       fetch(process.env.VUE_APP_URL + "customer/savegift/deleteProduct", {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },

        body: JSON.stringify({
          product_id: giftproduct.productId,
          user_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.loader = false;
            this.$delete(this.giftproducts, index);
            this.$swal.fire("Deleted", data.message, "success");
          } else {
            this.loader = false;
            this.$swal.fire("Error!", data.message, "error");
          }
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
        });


                    }


                })
    },
  },
};
</script>